export default function UseToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const saveToken = token => {
    sessionStorage.setItem('token', JSON.stringify(token));
  };

  const deleteToken = () => {
    sessionStorage.removeItem('token');
  }

  const checkToken = () => {
    return (getToken() != null)
  }

  return {
    saveToken,
    getToken,
    deleteToken,
    checkToken,
  }
}