import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import stonks from '../../assets/stonks.png'
import stats from '../../assets/icon/stats.png'
import sells from '../../assets/icon/sells.png'
import account from '../../assets/icon/account.png'
import logout from '../../assets/icon/logout.png'

import './Menu.css';

export default function Menu(active) {

  return (
    <Col id="menu" md={1}>
      <div>
        <div id="logo">
          <img src={stonks} alt="stonks" />
        </div>
        <div className="menuItem">
          <img src={sells} alt="sells" />
          <NavLink to="/" >
            Sells
          </NavLink>
        </div>
        <div className="menuItem">
          <img src={stats} alt="stats" />
          <NavLink to="/stats" >
            Stats
          </NavLink>
        </div>
        <div className="menuItem">
          <img src={account} alt="account" />
          <NavLink to="/account">
            Account
          </NavLink>
        </div>
        <div className="menuItem">
          <img src={logout} alt="logout" />
          <NavLink to="/logout">
            Logout
          </NavLink>
        </div>
      </div>
    </Col>
  )
}