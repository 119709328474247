import { useState, useEffect } from 'react';
import { CardMarketApi } from '../../functions/cardMarket';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './order.css';

export default function BoxOrders(props) {

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const flag = importAll(require.context('../../assets/flag', false, /\.(png|jpe?g|svg)$/));


    const [orderArray, setOrderArray] = useState([]);
    const [filteredArray, setfilteredArray] = useState([]);
    // const [sellingLang, setSellingLang] = useState([]);
    // const [topSeller, setTopSeller] = useState([]);

    const handleFilter = async (e, c, l) => {
        e.preventDefault();

        let filtered = orderArray.filter(el => el.country === c && el.language === l)
        setfilteredArray(filtered);
    }

    const handleReset = async (e) => {
        e.preventDefault();
        setfilteredArray(orderArray);
    }

    const myOrder = (orders) => {
        let a = orders.filter(el => el.name === "Naskay");

        const b = [];

        a.forEach((c, i) => {
            // setSellingLang(current => [...current, c.language]);
            b.push(<Button key={i} variant="primary" size="sm" onClick={function (e) { handleFilter(e, c.country, c.language) }}>{c.country + " : " + c.language}</Button>)
        })

        return b;
    }

    useEffect(() => {
        (async function () {
            CardMarketApi().getOfferPerBox(props.name).then(
                a => {
                    setOrderArray(a.orders);
                    setfilteredArray(a.orders);
                }
            );
        })();

    }, [props.name]);

    const genOrder = (arr) => {
        let boxes = [];
        arr.forEach(
            (el, i) => {
                boxes.push(
                    <tr key={i}>
                        <td className='large-cell'><a href={el.link}>{el.name}</a></td>
                        <td className='small-cell a-right'>{el.stats}</td>
                        <td className='small-cell a-center'>
                            <OverlayTrigger
                                placement={"top"}
                                overlay={<Tooltip>{el.country}</Tooltip>}>
                                <img className='countryImg' src={flag[el.country + ".svg"]} alt={el.country} />
                            </OverlayTrigger>
                        </td>
                        <td className='small-cell a-center'>
                            <OverlayTrigger
                                placement={"top"}
                                overlay={<Tooltip>{el.language}</Tooltip>}>
                                <img className='countryImg' src={flag[el.language + ".svg"]} alt={el.language} />
                            </OverlayTrigger>
                        </td>
                        <td className='medium-cell a-right'>{el.price}</td>
                        <td className='small-cell a-right'>{el.quantity}</td>
                        <td>{el.comment}</td>
                    </tr>);
            }
        )
        return boxes;
    }


    return (
        <div id="container">
            <div className="sticky-top navBox">
                <h3><a href={"https://www.cardmarket.com/fr/Magic/Products/Booster-Boxes/"+props.name}>{props.name.replaceAll("-", " ")}</a></h3>
                {orderArray.length !== 0 ? myOrder(orderArray) : "Loading"}
                <Button variant="secondary" size="sm" onClick={function (e) { handleReset(e) }}>Reset</Button>
            </div>
            <div id="orderTable">
                <Table bordered striped hover variant="dark">
                    <thead>
                        <tr>
                            <th>Seller</th>
                            <th>Stats</th>
                            <th>Land</th>
                            <th>Lang</th>
                            <th className='a-center'>€</th>
                            <th>Qty</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderArray.length !== 0 && genOrder(filteredArray)}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
