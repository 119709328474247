import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UseToken from '../UseToken';
import './Login.css';

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });

        if (token.status) {
            UseToken().saveToken(token);
            console.log(token);
            return navigate("/");
        }


    }

    return (
        <div id="back">
                <form onSubmit={handleSubmit}>
                    <div className="login-wrapper">
                        <div><input type="text" placeholder='username' onChange={e => setUserName(e.target.value)} /></div>
                        <div><input type="password" placeholder='password' onChange={e => setPassword(e.target.value)} /></div>
                        <div>
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </form>
        </div>
    )
}
