import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import UseToken from '../UseToken';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Menu from '../Menu/Menu';
import './Account.css';



async function addAccount(username, account) {
    console.log(username + " " + account);
    return fetch(process.env.REACT_APP_API_URL+'/users/accounts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, account })
    })
        .then(data => data.json())
}

async function getAccount(userId) {
    return fetch(process.env.REACT_APP_API_URL+'/users/' + userId + '/accounts', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(data => data.json())
}

async function delAccount(username, account) {
    return fetch(process.env.REACT_APP_API_URL+'/users/accounts', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, account })
    })
        .then(data => data.json())
}

export default function Account() {

    const [accountName, setAccountName] = useState('');
    const [accountArray, setAccountArray] = useState([]);

    const token = UseToken().getToken();


    let navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const accArray = await addAccount(token.username, accountName);
        setAccountArray(accArray);
        setAccountName("");
    }

    const dispAcc = (arr) => {
        let div = []
        arr.forEach(ele => div.push(<div key={ele}>{ele}<button type="button" onClick={function (e) { handleDelete(e, token.username, ele) }}>X</button></div>))
        return div;
    }

    const handleDelete = async (e, u, a) => {
        e.preventDefault();
        setAccountArray(await delAccount(u, a));
    }

    useEffect(() => {

        if (!UseToken().checkToken()) {
            return navigate("/");
        }

        if (accountArray.length === 0) {
            (async function () {
                const updateArray = await getAccount(token.id);
                setAccountArray(updateArray);
            })();

        }

    }, [accountArray]);


    return (
        <Container fluid>
            <Row>
                <Menu />
                <Col md={11}>
                    <div>
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <input type="text" placeholder='Add Account' value={accountName} onChange={e => { setAccountName(e.target.value); e.preventDefault() }} />
                                </div>
                                <div>
                                    <button type="submit">Add</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            {dispAcc(accountArray)}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
