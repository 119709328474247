import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UseToken from '../UseToken';
import Orders from '../BoxOrder/Orders.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Menu from '../Menu/Menu';
import './Main.css';

function Main() {

  const [accountArray, setAccountArray] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!UseToken().checkToken()) {
      return navigate("/login");
    }

  });

  return (
    <Container fluid>
      <Row>
        <Menu />
        <Col md={11} className="neon">
          <Orders accountArray={accountArray} />
        </Col>
      </Row>
    </Container>
  );
}

export default Main;
