import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import './App.css';
import Main from '../Main/Main';
import Login from '../Login/Login'
import Logout from '../Logout/Logout';
import Account from '../Account/Account';

function App() {


  useEffect(() => { document.body.style.backgroundColor = "black";
                    document.body.style.overflow="hidden" }, [])

  
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/account" element={<Account />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          
        </Routes>
      </BrowserRouter>
  );
}

export default App;