import { useState, useEffect } from 'react';
import { CardMarketApi } from '../../functions/cardMarket';
import BoxOrders from './BoxOrders';
import UseToken from '../UseToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import './order.css';

export default function Orders() {

    const [boxArray, setBoxArray] = useState([]);
    const [activeBox, setActiveBox] = useState("");
    const [topSellerBox, setTopSellerBox] = useState("");

    const token = UseToken().getToken();

    useEffect(() => {

        if (boxArray.length === 0) {
            (async function () {
                const boosters = await CardMarketApi().getBoxFromUser(token.id);
                setBoxArray(boosters);
            })();
        }
        else {
            (async function () {

                let allPromBox = [];

                for (const ele of boxArray) {
                    allPromBox.push(getAllTopSeller(ele, "Naskay"));
                }

                let resArray = await Promise.all(allPromBox);
                setTopSellerBox(resArray);         

            })();
        }

    }, [boxArray.length]);

    const getAllTopSeller = async (box, seller) => {

        const status = await CardMarketApi().getAllTopSellerStatus(box, seller);

        return status;
    }


    const setCurrentBox = (e, active) => {
        e.preventDefault();
        setActiveBox(active);
    }

    const displayTableBox = (boxes) => {
        let tBoxes = [];
        boxes.forEach(
            (el, i) => {

                let t = 0;
                let f = 0;
                let state;

                for (const el of topSellerBox[i]) {
                    if (el.top) {
                        t++;
                    }
                    else {
                        f++;
                    }
                }

                if (t>0)
                {
                    if (f===0)
                    {
                        state = "success"
                    }
                    else{
                        state = "warning"
                    }
                }
                else
                {
                    state = "danger"
                }

                tBoxes.push(<ListGroup.Item key={i} className="item" variant={state} onClick={function (e) { setCurrentBox(e, el) }}>
                    {el.replaceAll("-", " ")}
                </ListGroup.Item>);
            }
        )
        return tBoxes;
    }


    return (
        <Container fluid>
            <Row>
                <Col lg={2} >
                    <div className="sticky-top" id="nav">
                        <div className="navBox">
                            <h3>Booster Box</h3>
                        </div>
                        <div className="list">
                            <ListGroup className="rounded-0">
                                {topSellerBox.length !== 0 ? displayTableBox(boxArray) : <Spinner animation="border" variant="light"></Spinner>}
                            </ListGroup>
                        </div>
                    </div>
                </Col>

                <Col lg={10}>
                    {activeBox !== "" ? <BoxOrders name={activeBox} /> : "Select a box in the left list"}
                </Col>
            </Row>
        </Container>
    )
}
