import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import UseToken from '../UseToken';

function Logout() {

  const navigate = useNavigate();

  useEffect(() => {
    UseToken().deleteToken();
    return navigate("/");
  });

}

export default Logout;