export function CardMarketApi() {

    async function getBoxFromUser(userId) {

        return fetch(process.env.REACT_APP_API_URL+'/boxes/users/'+userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(a => { return a.json() }).then(b => { return b });
    }

    async function getOfferPerBox(boxName) {

        return fetch(process.env.REACT_APP_API_URL+'/boxes/'+boxName+'/orders', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(a => { return a.json() }).then(b => { return b });
    }

    async function getUser(code) {

        var details = {
            'code': code
        };

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        return fetch(process.env.REACT_APP_API_URL+'/user/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(a => { return a.json() }).then(b => { return b; });

    }

    async function getUserRole(user_id) {

        return fetch(process.env.REACT_APP_API_URL+'/user/' + user_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(a => { return a.json() }).then(b => { return b });
    }

    async function getAllTopSellerStatus(box,seller) {

        return fetch(process.env.REACT_APP_API_URL+'/boxes/'+box+'/users/'+seller+'/checkTopSeller', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(a => { return a.json() }).then(b => { return b });
    }

    return {
        getUser,
        getUserRole,
        getBoxFromUser,
        getOfferPerBox,
        getAllTopSellerStatus,
    }
}

export default CardMarketApi;